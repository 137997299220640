import React from 'react';


const AboutOne = () => {
    return (
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <h2 className="title mb--40">feliXart is now 10 years old.</h2>
                                <p>"feliXart is a 3D studio founded by a young and experienced team. Our motivation is to raise our business, which we are passionate about, above world standards, to be the champion of innovations in this technology, to reduce the need for trained people in our country and to make our company a center of attraction in its sector.” -feliXart 2013 </p>
                                <p>On the way we paved with these ideas; We have progressed by reaching our goals for years, and we have gained many friends and experiences in this adventure. </p>
                                <h2 className="title mb--40">Our Services: </h2>
                                <h6>Mobile-Web Apps, VR-AR Development. Digital Marketing Consulting.</h6>

                            </div>
                        </div>
                    </div>



                </div>
            </div>

    )
}

export default AboutOne;