import React from 'react';
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">New Age Agency.</h1> <h5> AR, Mobile Apps, Web , Digital Marketing.</h5>
                    <p>#FEELTHEFUTURE</p>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large">Get in Touch</Link>
                    </div>
                </div>

                <div className="banner-social">
                <   div className="border-line" />
                    <ul className="list-unstyled social-icon">
                        <li><a href="https://twitter.com/felixartcomtr"><FaTwitter /> twitter</a></li>
                        <li><a href="https://www.linkedin.com/company/felixart"><FaLinkedinIn /> Linkedin</a></li>
                    </ul>
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">

                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFour;