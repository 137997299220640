import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';

import ProjectOne from '../component/project/ProjectOne';

import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BannerFour from "../component/banner/BannerFour";


const DigitalAgency = () => {

    return (
        <>
        <SEO title="New Age Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BannerFour />
            <AboutOne />
            <ProjectOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

