import React from 'react';


const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">

                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>

                                    <ul>
                                        <p>Email: <a href="mailto:mtd@felixart.com.tr<">mtd@felixart.com.tr</a></p>
                                            <p>Phone: <a href="tel:905334207792">+90 533 420 77 92</a></p>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <p>Check Out Our Most Interesting Works</p>

                            <div>
                                <a className="axil-btn btn-fill-primary btn-large"
                                   href="https://www.youtube.com/channel/UC6oSxrGkA_HJoEqZpwlk65g">YouTube</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()} Felixart</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;