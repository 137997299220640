import React from 'react';
import { Link } from 'react-router-dom';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu" >

                <a className="axil-btn btn-fill-primary2 btn-large"
                   href="https://www.felixart.space">Blog</a>
                <li><Link to={process.env.PUBLIC_URL + "/team"}>Join</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;