import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUpTwo from '../component/counterup/CounterUpTwo';


const Team = () => {

    return (
        <>
        <SEO title="Work Remote Freelance | Join FeliXart" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Join the best talents"
                paragraph ="If you are a skilled developer or designer; reach us to work on our projects remote."

                />
                <CounterUpTwo />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Team;